import {defineStore} from "pinia";
import {api} from "@/service/http-common.js";


export const useAccount = defineStore('account', {
    state:() => ({
        id: null,
        username: null,
        token: null,
        permissions:  null,
        isExecutiveBoardMember: null,
        isUnitSupervisor: null,
        fullName: null,
        title: null,
    }),
    getters: {
        _token: (state) => state.token,
        _username: (state) => state.username,
        _permissions: (state) => state.permissions,
        _id: (state) => state.id,
        _fullName: (state) => state.fullName,
        _title: (state) => state.title
    },
    actions: {
        async login(data){
            const response = (await api.post("/auth/login", data)).data
            this.id = response?.data?.id;
            this.username = response?.data?.username;
            this.token = response?.data?.token;
            this.permissions = response?.data?.permissions;
            this.fullName = response?.data?.fullName;
            this.title = response?.data?.title;
        },
        async forgotPassword(data){
            return await api.post('/auth/forgotPassword',data);
        },
        async resetPassword(data){
            return await api.post('/auth/resetPassword',data);
        },
        async changePassword(data){
            return await api.post('/account/changePassword',data);
        }
    },
    persist: true,
})
