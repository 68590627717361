<template>
  <div
    class="nk-sidebar nk-sidebar-fixed is-dark"
    :class="{'nk-sidebar-mobile': isMobile}"
    @mouseenter="sidebarMenuMouseEnter"
    @mouseleave="sidebarMenuMouseLeave"
  >
    <div class="nk-sidebar-element nk-sidebar-head">
      <div class="nk-menu-trigger">
        <a href="javascript:void(0)" class="nk-nav-toggle nk-quick-nav-icon d-xl-none" @click="toggleMobileMenu"><em class="icon ni ni-arrow-left"></em></a>
        <a
          href="javascript:void(0);"
          @click="toggleMenu"
          class="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
        >
          <em class="icon ni ni-menu"></em>
        </a>
      </div>
      <div class="nk-sidebar-brand">
        <router-link to="/" class="logo-link nk-sidebar-logo">
          <img class="logo-light logo-img" src="@/assets/images/aygun-logo-white.png" srcset="@/assets/images/aygun-logo-white.png" alt="logo">
          <img class="logo-dark logo-img" src="@/assets/images/aygun-logo-white.png" srcset="@/assets/images/aygun-logo-white.png" alt="logo-dark">
        </router-link>
      </div>
    </div>
    <div class="nk-sidebar-element nk-sidebar-body">
      <div class="nk-sidebar-content">
        <SimpleBar style="height: 100%; overflow-y: auto">
          <div class="nk-sidebar-menu">
            <ul class="nk-menu">
              <Component
                  :is="resolveNavItemComponent(item)"
                  v-for="(item,index) in navItems"
                  :key="index"
                  :item="item"
              />
            </ul>
          </div>
        </SimpleBar>
      </div>
    </div>
  </div>

</template>

<script setup>
import { SimpleBar } from 'simplebar-vue3';
import navItems from '@/components/Navigation/pages.js'
import { NavGroup, NavLink, NavSectionTitle } from "@/components/navigation";
import {useBreakpoints} from "@/composable/utils.js";

const {isMobile} = useBreakpoints()

const toggleMenu = () => {
  const sidebar = document.querySelector('.nk-sidebar');
  sidebar.classList.toggle('is-compact');
  sidebar.classList.toggle('nk-sidebar-active')
}

const toggleMobileMenu = () => {
  const sidebar = document.querySelector('.nk-sidebar');
  sidebar.classList.toggle('is-compact');
  sidebar.classList.toggle('nk-sidebar-active')
  const overlay = document.querySelector('.nk-sidebar-overlay')
  overlay.style.display = "none"
}

const resolveNavItemComponent = item => {
  if ('heading' in item)
    return NavSectionTitle
  if ('children' in item)
    return NavGroup

  return NavLink
}

const sidebarMenuMouseEnter= () => {
  const sidebar = document.querySelector('.nk-sidebar');
  if(sidebar.classList.contains('is-compact')){
    sidebar.classList.add('has-hover')
  }
}

const sidebarMenuMouseLeave = () => {
  const sidebar = document.querySelector('.nk-sidebar');
  if(sidebar.classList.contains('has-hover')){
    sidebar.classList.remove('has-hover')
  }
}

</script>

<style scoped>

</style>
