import {useAccount} from "@/store/account.js";
import dayjs from "dayjs";
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver';


export const checkStr = (str) => {
    return [undefined,null,""].includes(str);
}

export const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
}

export const formatNumber = (value) => {
    let trNumberFormat = Intl.NumberFormat('tr-TR');
    return trNumberFormat.format(value);
}

export const formatDateWithTime = (date) => {
    return new Date(date).toLocaleString();
}

export const validateEmail = (email) => {
    return String(email).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const datetimeLocal = (date) => {
    let now;

    if(checkStr(date)){
        now = new Date()
    }else {
        now = new Date(date)
    }

    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0,16);
}

export const getFileExtension = (value) => {
    return value.toString().split('.');
}

export const isMobile = () => {
    return !!(window.navigator.userAgent.match(/Android/i)
        || window.navigator.userAgent.match(/webOS/i)
        || window.navigator.userAgent.match(/iPhone/i)
        || window.navigator.userAgent.match(/iPad/i)
        || window.navigator.userAgent.match(/iPod/i)
        || window.navigator.userAgent.match(/BlackBerry/i)
        || window.navigator.userAgent.match(/Windows Phone/i));
}

export const getStatusAsString = (status) => {
    switch (status){
        case 1:
            return "Talep Beklemede";
        case 2:
            return "Talep Canias'a Girildi";
        case 3:
            return "Talep Onay Bekliyor";
        case 4:
            return "Sipariş Verildi";
        case 5:
            return "Teslim Edildi";
        case 6:
            return "Reddedildi";
        case 20:
            return "Reddedildi";
    }
}

export const getPurchaseDept = status => {
    switch (status){
        case "motor":
            return "Motor";
        case "telsepet":
            return "Tel Sepet";
        case "ilight":
            return "I-Light";
        case "konteyner":
            return "Konteyner";
        case "drenay":
            return "Drenay";
    }
}

export const checkPermission = (permission) => {
    const _useAccount = useAccount();
    let userRoles = _useAccount._permissions;

    if (!userRoles)
        return false;

    if (userRoles.toString().split(",").find(w => w === 'C'))
        return true;

    return userRoles.toString().split(",").indexOf(permission.toString()) > -1;
}

export const checkPermissionOr = (permissions) => {
    const _useAccount = useAccount();
    let userRoles = _useAccount._permissions;

    if (!userRoles)
        return false;

    if (userRoles.toString().split(",").find(w => w === 'C'))
        return true;

    let accessArr = []

    permissions.forEach(f => {
        let index = userRoles.toString().split(",").indexOf(f.toString())
        accessArr.push(index)
    });

    return accessArr.filter(f => f > -1).length > 0;
}

export const checkPermissionAnd = (permissions) => {
    const _useAccount = useAccount();
    let userRoles = _useAccount._permissions;

    if (!userRoles)
        return false;

    if (userRoles.toString().split(",").find(w => w === 'C'))
        return true;

    let accessArr = []

    permissions.forEach(f => {
        let index = userRoles.toString().split(",").indexOf(f.toString())
        accessArr.push(index)
    });

    return !accessArr.filter(f => f === -1).length > 0
}

export const compareTime = (date,startTime,endTime) => {
    const shiftStart = startTime.toString().split(':')
    const shiftEnd = endTime.toString().split(':')

    const date1 = dayjs(date).hour(+shiftStart[0]).minute(+shiftStart[1])
    const date2 = dayjs(date).hour(+shiftEnd[0]).minute(+shiftEnd[1])

    return  dayjs(date2).isAfter(dayjs(date1))
}

export const getMinutes = (date,startTime,endTime) => {
    if(checkStr(date) || checkStr(startTime) || checkStr(endTime))
        return

    const shiftStart = startTime.toString().split(':')
    const shiftEnd = endTime.toString().split(':')

    const date1 = dayjs(date).hour(+shiftStart[0]).minute(+shiftStart[1])
    const date2 = dayjs(date).hour(+shiftEnd[0]).minute(+shiftEnd[1])

    return dayjs(date2).diff(date1, 'minute')
}

export const isDayWeekend = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return (dayOfWeek === 6) || (dayOfWeek  === 0);
}


export const exportAsExcelFile = (data,fileName) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';

    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = { Sheets: {'data': worksheet}, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

    const datas = new Blob([excelBuffer], {type: EXCEL_TYPE})
    FileSaver.saveAs(datas, fileName + "_" + new Date().toLocaleDateString() + EXCEL_EXTENSION)
}
