<template>
  <li class="nk-menu-item has-sub" :class="{active: isGroupOpen}" v-if="menuPermission(props.item)">
    <a href="javascript:void(0);" class="nk-menu-link nk-menu-toggle" @click="isGroupOpen = !isGroupOpen">
      <span class="nk-menu-icon">
        <em :class="item.icon"></em>
      </span>
      <span class="nk-menu-text">{{ item.title }}</span>
    </a>
      <Transition name="fade" mode="out-in">
        <ul class="nk-menu-sub" v-if="isGroupOpen">
          <li class="nk-menu-item" v-for="child in item.children" :key="child">
            <RouterLink :to="child.to" class="nk-menu-link" v-if="menuPermission(child)">
              <span class="nk-menu-text">{{ child.title }}</span>
            </RouterLink>
          </li>
        </ul>
      </Transition>
  </li>
</template>

<script setup>
import {openGroups} from "@/components/Navigation/utils";
import {ref, watch} from "vue";
import {checkPermission, checkPermissionOr} from "@/service/helper.js";

const isGroupOpen = ref(false)

const props = defineProps({
  item: {
    type: null,
    required: true,
  },
})

const menuPermission = per => {
  if(per.permissionType === 'single'){
    return checkPermission(per?.permission)
  }
  else if(per.permissionType === 'or'){
    return checkPermissionOr(per?.permission)
  }
  else if(per.permissionType === 'and'){
    return checkPermissionOr(per?.permission)
  }
  return true
}

const childPermission =

watch(isGroupOpen, val => {
  const grpIndex = openGroups.value.indexOf(props.item.title)

  if(val && grpIndex === -1){
    openGroups.value.push(props.item.title)
  } else if(!val && grpIndex !== -1){
    openGroups.value.splice(grpIndex,1)
  }
})

watch(openGroups, val => {
  const lastOpenedGroup = val[val.length -1]

  if (lastOpenedGroup === props.item.title)
    return
  isGroupOpen.value = false

}, {deep: true})

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.50s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
