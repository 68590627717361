<script setup lang="ts">
import {computed} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()

const layout = computed(() => {
  return route.meta.layout || 'div'
})
</script>

<template>
  <component :is="layout || 'div' ">
    <router-view />
  </component>
  <Toast position="top-center" group="tc" />
  <Toast position="top-center" />
  <ConfirmPopup></ConfirmPopup>
  <ConfirmDialog
    group="pt"
    :pt="{
      closeButton: {style: 'display: none'}
    }"
  >
  </ConfirmDialog>
</template>

<style scoped>
</style>
