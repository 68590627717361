import { createApp } from 'vue'
import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from '@/router'

import VueApexCharts from "vue3-apexcharts";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmDialog from "primevue/confirmdialog";
import Tooltip from 'primevue/tooltip';
import VueNumberFormat from "vue-number-format";

//import * as Sentry from "@sentry/vue";


import 'element-plus/dist/index.css'
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import './assets/css/dashlite.css'
import './assets/css/theme-green.css'
import './assets/css/themify-icons.css'
import '@vueform/multiselect/themes/default.css'

import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/collapse/style/css'
import 'element-plus/es/components/collapse-item/style/css'

import './style.css'


window.addEventListener('resize', () => {
    if(window.innerWidth > 997){
        const overlay = document.querySelector('.nk-sidebar-overlay')
        overlay.style.display = "none"
    }
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App);

// Sentry.init({
//     app,
//     dsn: "https://7ccba8954871926774a83d6914434851@o4505856559939584.ingest.us.sentry.io/4507824934879232",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false
//         }),
//     ],
//     beforeSend(event){
//         return event
//     },
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Sentry.replayIntegration({
//     maskAllText: false,
//     blockAllMedia: false,
// })


app.use(router)
app.use(pinia)

// PrimeVue Usages
app.use(PrimeVue)
app.use(ToastService);
app.use(ConfirmationService);

// PrimeVue Components
app.component("Toast",Toast)
app.component('ConfirmPopup',ConfirmPopup)
app.component('ConfirmDialog',ConfirmDialog)

app.use(VueApexCharts);

// Vue Number Format Usage
app.use(VueNumberFormat)

app.directive('tooltip', Tooltip);


app.mount('#app');



