import {computed, onMounted, onUnmounted, onBeforeUnmount ,ref} from "vue";


export const useBreakpoints = () => {
    let windowWidth = ref(window.innerWidth)

    const onWidthChange = () => windowWidth.value = window.innerWidth
    onMounted(() => window.addEventListener('resize', onWidthChange))
    onUnmounted(() => window.removeEventListener('resize', onWidthChange))

    const type = computed(() => {
        if (windowWidth.value < 420) return 'mb'
        if (windowWidth.value >= 420 && windowWidth.value < 576) return 'sm'
        if (windowWidth.value >= 576 && windowWidth.value < 768) return 'md'
        if (windowWidth.value >= 768 && windowWidth.value < 992) return 'lg'
        if (windowWidth.value >= 1200) return 'lg'
        return null
    })

    const isMobile = computed(() => {
        return windowWidth.value < 992
    })

    const width = computed(() => windowWidth.value)

    return {width, type, isMobile}
}

export const getMonthAsString = monthIndex => {
    const aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
    return aylar[monthIndex];
}

export const calculateMinuteToHour = (minute) => {
    minute = minute * 60

    if(minute === 0)
        return "--:--";

    let gun = 0, saat = 0, dakika = 0;

    while (minute > 0) {
        // if(minute >= 32400){ // Gün için
        //     gun += 1;
        //     minute = minute - 32400;
        // }
        if(minute >= 3600){ // Saat için
            saat += 1;
            minute = minute - 3600;
        }
        else if(minute >= 60){ // Dakiak İçin
            dakika += 1;
            minute = minute - 60;
        }
        else {
            minute = 0;
        }
    }

    let result = "";

    if(saat === 0){
        result += "00:"
    }else if(saat.toString().length === 1){
        result += `0${saat}:`
    }else{
        result += `${saat}:`
    }

    if(dakika === 0){
        result += "00"
    }else if(dakika.toString().length === 1){
        result += `0${dakika}`
    }else{
        result += `${dakika}`
    }

    return result;
}
