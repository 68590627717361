import axios from "axios";
import {useAccount} from "@/store/account.js";
import router from "../router/index.js"

export const api = axios.create({
    baseURL: import.meta.env.VITE_WEB_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-type": "application/json",
    }
})

api.interceptors.request.use((config) => {
    const store = useAccount()
    config.headers = {
        'Authorization': `Bearer ${store._token}`
    }
    return config
})

api.interceptors.response.use((config) => {
    return config
}, err => {
    const status = err.response?.status || 500;
    const store = useAccount()

    switch (status){
        case 401: {
            store.$reset();
            router.push({ name : 'LoginPage' });
            break;
        }
    }
    return Promise.reject(err);
})