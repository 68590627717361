import {createRouter, createWebHistory} from "vue-router";
import {useAccount} from "@/store/account.js";
import BlankLayout from "@/layout/Blank/BlankLayout.vue";
import MainLayout from "@/layout/Main/MainLayout.vue";

const routes = [
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import('@/pages/authentication/login.vue'),
        meta: {
            layout: BlankLayout,
            hideForAuth: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordPage',
        component: () => import('@/pages/authentication/forgot-password.vue'),
        meta: {
            layout: BlankLayout,
            hideForAuth: true
        }
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPasswordPage',
        component: () => import('@/pages/authentication/reset-password.vue'),
        meta: {
            layout: BlankLayout,
            hideForAuth: true
        }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/pages/dashboard.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/purchase/new',
        name: 'NewPurchase',
        component: () => import('@/pages/purchasing/newpurchase.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/purchase/my-purchase',
        name: 'MyPurchase',
        component: () => import('@/pages/purchasing/mypurchase.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/purchase/all-purchases',
        name: 'AllPurchases',
        component: () => import('@/pages/purchasing/allpurchases.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/purchase/pending-purchases',
        name: 'PendingPurchases',
        component: () => import('@/pages/purchasing/pendingpurchase.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shipment/incoming-shipments',
        name: 'IncomingShipments',
        component: () => import('@/pages/shipment/incomingshipment.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/vehicle/my-requests',
        name: 'MyVehicleRequests',
        component: () => import('@/pages/vehicle/myvehiclerequests.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/vehicle/pending-responsible-approvals',
        name: 'ResponsiblePendingVehicleRequests',
        component: () => import('@/pages/vehicle/responsiblependingvehiclerequests.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/vehicle/pending-approvals',
        name: 'PendingVehicleRequests',
        component: () => import('@/pages/vehicle/pendingvehiclerequests.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/vehicle/vehicle-requests-list',
        name: 'VehicleRequestsList',
        component: () => import('@/pages/vehicle/vehiclerequestlist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/vehicle/completed-vehicle-requests-list',
        name: 'CompletedVehicleRequestsList',
        component: () => import('@/pages/vehicle/completedvehiclerequestlist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/expense/my-expense-requests',
        name: 'MyExpenseRequestList',
        component: () => import('@/pages/expense/myexpenserequests.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/expense/pending-expense-requests',
        name: 'PendingExpenseRequests',
        component: () => import('@/pages/expense/pendingexpenserequests.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/expense/expense-request-list',
        name: 'ExpenseRequestList',
        component: () => import('@/pages/expense/expenserequestlist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/product/list',
        name: 'ProductList',
        component: () => import('@/pages/product/productlist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/settings/user',
        name: 'UserList',
        component: () => import('@/pages/settings/userlist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/settings/employee',
        name: 'EmployeeList',
        component: () => import('@/pages/settings/employeelist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/settings/company',
        name: 'CompanyList',
        component: () => import('@/pages/settings/companylist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/shift-employee-list',
        name: 'ShiftEmployeeList',
        component: () => import('@/pages/shift/shiftemployeelist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/new-shift',
        name: 'NewShift',
        component: () => import('@/pages/shift/newshift.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/import-employee-working',
        name: 'ImportEmployeeWorking',
        component: () => import('@/pages/shift/import-employee-working.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/shift-permissions',
        name: 'ShiftPermissions',
        component: () => import('@/pages/shift/shiftpermission.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/approve',
        name: 'ApproveShift',
        component: () => import('@/pages/shift/approveshift.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/list',
        name: 'ShiftList',
        component: () => import('@/pages/shift/shiftlist.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/shift-period',
        name: 'ShiftPeriod',
        component: () => import('@/pages/shift/shiftperiod.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/calendar',
        name: 'ShiftCalendar',
        component: () => import('@/pages/shift/shift-calendar.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/my-list',
        name: 'MyShiftList',
        component: () => import('@/pages/shift/my-shift-list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/rapor',
        name: 'ShiftRapor',
        component: () => import('@/pages/shift/shift-rapor.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/normal-rapor',
        name: 'NormalShiftRapor',
        component: () => import('@/pages/shift/normal-shift-rapor.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/late-report',
        name: 'ShiftLateReport',
        component: () => import('@/pages/shift/late-rapor.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/shift/normal-rapor-2',
        name: 'NormalReport2',
        component: () => import('@/pages/shift/normal-shift-user-rapor.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/expo-list',
        name: 'ExpoList',
        component: () => import('@/pages/crm/expo-list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/expo-records',
        name: 'ExpoRecords',
        component: () => import('@/pages/crm/expo-records.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/analytics',
        name: 'CrmAnalytics',
        component: () => import('@/pages/crm/dashboard/analytics.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/task/create',
        name: 'TaskCreate',
        component: () => import('@/pages/crm/task/create.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/task/list',
        name: 'TaskList',
        component: () => import('@/pages/crm/task/list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },    
    {
        path: '/crm/action/create',
        name: 'ActionCreate',
        component: () => import('@/pages/crm/action/create.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/task/kanban',
        name: 'TaskKanbanList',
        component: () => import('@/pages/crm/task/kanban.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/organization/list',
        name: 'OrganizationList',
        component: () => import('@/pages/crm/organization/list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/crm/organization/detail/:code',
        name: 'OrganizationDetail',
        component: () => import('@/pages/crm/organization/detail.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock-category/create',
        name: 'ItWarehouseStockCategoryCreate',
        component: () => import('@/pages/it-warehouse/stock-category-create.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock-category/list',
        name: 'ItWarehouseStockCategoryList',
        component: () => import('@/pages/it-warehouse/stock-category-list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock-category/edit/:id',
        name: 'ItWarehouseStockCategoryEdit',
        component: () => import('@/pages/it-warehouse/stock-category-edit.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock/create',
        name: 'ItWarehouseStockCreate',
        component: () => import('@/pages/it-warehouse/stock-create.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock/list',
        name: 'ItWarehouseStockList',
        component: () => import('@/pages/it-warehouse/stock-list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock/edit/:id',
        name: 'ItWarehouseStockEdit',
        component: () => import('@/pages/it-warehouse/stock-edit.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock/employee-list',
        name: 'ItWarehouseStockEmployeeList',
        component: () => import('@/pages/it-warehouse/stock-employee-list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock-brand/list',
        name: 'ItWarehouseStockBrandList',
        component: () => import('@/pages/it-warehouse/stock-brand-list.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock-brand/create',
        name: 'ItWarehouseStockBrandCreate',
        component: () => import('@/pages/it-warehouse/stock-brand-create.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/it-warehouse/stock-brand/edit/:id',
        name: 'ItWarehouseStockBrandEdit',
        component: () => import('@/pages/it-warehouse/stock-brand-edit.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/sales-report/weekly-report',
        name: 'SalesReport',
        component: () => import('@/pages/sales-report/weekly-report.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/sales-report/costumer-report',
        name: 'CostumerReport',
        component: () => import('@/pages/sales-report/costumer-report.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/maintenance/create',
        name: 'MaintenanceCreate',
        component: () => import('@/pages/maintenance/create.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    },
    {
        path: '/maintenance/chief-approve',
        name: 'MaintenanceChiefApprove',
        component: () => import('@/pages/maintenance/chief-approve.vue'),
        meta: {
            layout: MainLayout,
            requiresAuth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to,from,next) => {
    const _useAccount = useAccount()
    const isAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isHide = to.matched.some((record) => record.meta.hideForAuth);
    const loggedIn = _useAccount._token
    if(isAuth && !loggedIn){
        console.log("sa")
        return next({ name : "LoginPage" });
    } else if(isHide && loggedIn){
        console.log("as")
        return next({ name : "Dashboard" });
    } else {
        next();
    }
})

export default router
